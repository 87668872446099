import React, { useContext } from "react";
import GlobalStyles from 'styles/GlobalStyles';
import ReactGA from 'react-ga';

/*
 * This is the entry point component of this project. You can change the below exported default App component to any of
 * the prebuilt landing page components by uncommenting their import and export lines respectively.
 * See one of the landing page components to better understand how to import and render different components (Always
 * make sure if you are building your own page, the root component should be the AnimationRevealPage component. You can
 * disable the animation by using the disabled prop.
 *
 * The App component below is using React router to render the landing page that you see on the live demo website
 * and the component previews.
 *
 */

/* Use AnimationRevealPage as a wrapper component for your pages if you are building a custom one yourself */
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";

/*
 * Hero section is the top most section on the page. It contains the header as well.
 * So you dont need to import headers
 * separately
 */

 import AboutUsPage from "pages/AboutUs.js";
 import ContactUsPage from "pages/ContactUs.js";
 import RequestEarlyAccess from "pages/RequestEarlyAccess.js";
 import TermsOfServicePage from "pages/TermsOfService.js";
 import PrivacyPolicyPage from "pages/PrivacyPolicy.js";

import ProfessionalLandingPage from "MainLandingPage.js";
import FacilityLandingPage from "FacilityLandingPage.js";
import AgencyLandingPage from "pages/agency/AgencyLandingPage.js";
import Dashboard from "views/admin/shifts2";


import "@fortawesome/fontawesome-free/css/all.min.css";




import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);


function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;

  return (

    <>
      <GlobalStyles />
      <Router basename=''>
        <Routes>
          <Route path="/" element={<ProfessionalLandingPage />} />
//          <Route path="/" element={<AgencyLandingPage />} />
          <Route path="/facilities" element={<FacilityLandingPage />} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/requestearlyaccess" element={<RequestEarlyAccess />} />
          <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />
          <Route path="/termsofservice" element={<TermsOfServicePage />} />
          {/* <Route path="/shifts2" exact element={<Shifts2 />} /> */}
        </Routes>
      </Router>

    </>
  );
}

export default App;