import React, {useRef, useState} from "react";
import ReactGA from 'react-ga';
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import mockupImageSrc from "images/mobile-app.png"
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";
import { ContentWithPaddingXl, Container as ContainerBase } from "components/misc/Layouts";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import appleIconImageSrc from "images/apple-icon.png";
import googlePlayIconImageSrc from "images/google-play-icon.png"
import emailjs from '@emailjs/browser';

const Container = tw(ContainerBase)`bg-gray-900 -mx-8`
const Content = tw(ContentWithPaddingXl)``
const Row = tw.div`px-8 flex items-center relative z-10 flex-col lg:flex-row text-center lg:text-left justify-center`;

const ColumnContainer = tw.div`max-w-2xl`
const TextContainer = tw(ColumnContainer)``;
const Text = tw(SectionHeading)`text-gray-100 lg:text-left max-w-none text-3xl leading-snug`;
const Subheading = tw(SubheadingBase)`text-yellow-500 mb-4 tracking-wider`

const LinksContainer = tw.div`mt-8 lg:mt-16 flex flex-col items-center sm:block`
const Link = styled.a`
  ${tw`w-56 p-3 sm:p-4 text-sm sm:text-base font-bold uppercase tracking-wider rounded-full inline-flex justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 bg-gray-100 hocus:bg-gray-300 text-gray-900 hocus:text-gray-900 shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`}
  img {
    ${tw`inline-block h-8 mr-3`}
  }
  span {
    ${tw`leading-none inline-block`}
  }
`;

const ImageContainer = tw(ColumnContainer)`mt-16 lg:mt-0 lg:ml-16 flex justify-end`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`
const DecoratorBlob1 = tw(SvgDecoratorBlob1)`absolute bottom-0 left-0 w-80 h-80 transform -translate-x-20 translate-y-32 text-gray-800 opacity-50`
const DecoratorBlob2 = tw(SvgDecoratorBlob1)`absolute top-0 right-0 w-80 h-80 transform  translate-x-20 -translate-y-64 text-gray-800 opacity-50`

const Input = styled.input`
  ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-none py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

      border-bottom: 1px solid white; /* White underline */

  ::placeholder {
    ${tw`text-gray-500`}
  }

  &:focus {
    border-bottom: 1px solid pink; /* Change the underline color on focus */
  }
`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-700 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between mt-8 mb-6`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm text-gray-100`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-primary-600 text-gray-100 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-800 hocus:-translate-y-px hocus:shadow-xl`;

const Description = tw.p`mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-100`

export default ({
  subheading = "Mobile App Coming Soon",
//  text = "Take control of your career and time. Start today by downloading the app.",
  text = "Register for early access.",
  link1Text = "App Store",
  link1Url = "http://apple.com",
  link1IconSrc = appleIconImageSrc,
  link2Text = "Google Play",
  link2Url = "http://play.google.com",
  link2IconSrc = googlePlayIconImageSrc,
  pushDownFooter = false,
  imageSrc = mockupImageSrc
}) => {


    const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
    const [disabled, setDisabled] = useState(false);

    const sectionRef = useRef(null);
    const scrollToSection = () => {
        sectionRef.current.scrollIntoView({ behavior: 'smooth' });
      };

      const handleSubmit = (event) => {
      event.preventDefault();

          if (!fullName.trim() || !phoneNumber.trim() || !emailAddress.trim() || phoneNumber.length < 10 || phoneNumber.length > 12) {
            alert("Please fill in all the fields.");
            return;
          }
          var templateParams = {
              name: fullName,
              email: emailAddress,
              phone: phoneNumber,
          };

          // Perform form submission logic here
          emailjs.send(process.env.REACT_APP_CONVERTKIT_API_SERVICE_ID, process.env.REACT_APP_CONVERTKIT_EMAIL_TEMPLATE_INQUIRY, templateParams, process.env.REACT_APP_CONVERTKIT_API_PUBLIC_KEY);

          ReactGA.event({
            category: 'Visitor',
            action: 'Registered for healthcare professional early acccess'
          });

          alert("Thank you for your interest in the early access of our Healthcare Link mobile app. Further instructions will be sent to you as soon as we get closer to launch!");

          setDisabled(true);
        };

  return (
    <Container id="download" ref={sectionRef} css={pushDownFooter && tw`mb-20 lg:mb-24`}>
      <Content>
        <Row>
          <TextContainer>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Text>{text}</Text>
            <Description>Take control of your career. Be among the first to seize exclusive benefits: secure a premium rate, personalized support, and play a pivotal role in molding the future of healthcare staffing.</Description>
                      <form onSubmit={handleSubmit}>
            <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Full Name</Label>
                    <Input  id="name-input" type="text" name="name" onChange={(e) => setFullName(e.target.value)} value={fullName} placeholder="E.g. John Doe" />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="phone-input">Phone Number</Label>
                    <Input id="phone-input" type="phone" name="phone" onChange={(e) => setPhoneNumber(e.target.value)} value={phoneNumber} placeholder="1546878989" />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer>
                      <Label htmlFor="email-input">Email Address</Label>
                      <Input id="email-input" type="email" name="email" onChange={(e) => setEmailAddress(e.target.value)} value={emailAddress} placeholder="E.g. john@mail.com" />
                    </InputContainer>
                </Column>
              </TwoColumn>

          <SubmitButton disabled={disabled} type="submit" value="Submit">Submit</SubmitButton>
                    </form>
            {/* <LinksContainer>
              <Link href={link1Url}>
                <img src={link1IconSrc} alt=""/>
                <span>{link1Text}</span>
              </Link>
              <Link href={link2Url}>
                <img src={link2IconSrc} alt=""/>
                <span>{link2Text}</span>
              </Link>
            </LinksContainer> */}
          </TextContainer>
          <ImageContainer>
            <img src={imageSrc} alt="" tw="w-64"/>
          </ImageContainer>
        </Row>
        <DecoratorBlobContainer>
          <DecoratorBlob1/>
          <DecoratorBlob2/>
        </DecoratorBlobContainer>
      </Content>
    </Container>
  );
};

