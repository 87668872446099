import React, { useRef } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import ReactGA from 'react-ga';



import Hero from "components/hero/Facilities.js";
import Features from "components/features/FacilityWhy.js";

import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import RecentListings from "components/cards/ThreeColSlider.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Blog from "components/blogs/GridWithFeaturedPost.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStartedLight.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import DownloadApp from "components/cta/DownloadApp.js";
import Steps from "components/features/HowItWorks.js";
import Contact from "components/forms/FacilityContactForm.js";


const HighlightedText = tw.span`text-primary-600`

export default () => {
  // track pageview
  ReactGA.pageview(window.location.pathname);

  return (

    <AnimationRevealPage disabled={true}>
      <Hero />

      {/*<FeatureStats/>*/}

      <Steps />


      <Features
        heading={<>How we differ from staffing agencies</> }
      />


      {/*
      <Pricing
        heading={<>Flexible <HighlightedText>Plans</HighlightedText></>}
      />
    */}

      {/*
      <FAQ
        heading={<>Any <HighlightedText>Questions ?</HighlightedText></>}
      />


      <GetStarted/>
       */}

             <Contact />


      <Footer />
    </AnimationRevealPage>
  );
}
