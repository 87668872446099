import React from "react";
import styled from 'styled-components';

// components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/headers/HeaderStats.js";
import FooterAdmin from "components/footers/FooterAdmin.js";

import CardTable from "components/cards/CardTable.js";
import Table from "components/Table.js";
import "assets/styles/tailwind.css";


const getData = () => [
  {
    name: "Jane Cooper",
    email: "jane.cooper@example.com",
    title: "Regional Paradigm Technician",
    department: "Optimization",
    status: "Active",
    role: "Admin",
    imgUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
  },
  {
    name: "Cody Fisher",
    email: "cody.fisher@example.com",
    title: "Product Directives Officer",
    department: "Intranet",
    status: "Active",
    role: "Owner",
    imgUrl:
      "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
  }
];

export default function Tables() {
  const data = React.useMemo(() => getData(), []);

  return (
    <>

    <Sidebar />

    <div className="relative md:ml-64 bg-blueGray-100 ">
        <AdminNavbar />
        <HeaderStats />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
            <div className="w-full xl:w-1 mb-12 xl:mb-0 px-4">
              <Table />
            </div>
        </div>
    </div>

    </>
  );
}