import React, { Component, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import Modal from "components/Modal/Modal";
import emailjs from '@emailjs/browser';
import ReactGA from 'react-ga';


const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-center items-center max-w-screen-xl mx-auto md:pb-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:pb-8 lg:pt-24 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-center`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-center leading-tight`;
const Description = tw.p`mt-4 text-center md:text-center text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-lg mx-auto md:mx-0`
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0 w-96`;

const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

class FacilityContactForm extends Component {

    subheading = "Register for Early Access";
  heading = <>Skip the fees and <span tw="text-primary-600">streamline</span><wbr/> your staffing needs.</>;
  description = "Early access waives subscriptions fees and gives you priority access, dedicated support from our team and direct influence as you help build and optimize our service. Apply with your information below and someone from our team will reach out shortly.";
  submitButtonText = "Submit";
  formAction = "#";
  formMethod = "get";
  textOnLeft = true;

  constructor(props) {
  	super(props);
  	this.state = {
  	    name: '',
  	    company: '',
  	    address: '',
  	    email: '',
  	    phone: '',
  	    disabled: false,
  	    };
  	this.handleChange = this.handleChange.bind(this);
  	this.handleSubmit = this.handleSubmit.bind(this);
  	  	this.isValid = this.isValid.bind(this);


    }

    handleChange(e){
       this.setState({[e.target.name]: e.target.value});

    }

  handleSubmit(event){
    event.preventDefault();

    if(this.isValid())
    {
        var templateParams = {
            name: this.state.name,
            company_name: this.state.company,
            address: this.state.address,
            email: this.state.email,
            phone: this.state.phone
        };

	    emailjs.send(process.env.REACT_APP_CONVERTKIT_API_SERVICE_ID, process.env.REACT_APP_CONVERTKIT_EMAIL_TEMPLATE_INQUIRY, templateParams, process.env.REACT_APP_CONVERTKIT_API_PUBLIC_KEY);

      ReactGA.event({
        category: 'Visitor',
        action: 'Registered for facility early acccess'
      });

      alert("Thank you for your interest. Someone from the Healthcare Link team will be reaching out to you shortly to guide you through the next steps.");
      this.setState({disabled: true});
    }

  }

  isValid() {
    if(this.state.name === '' || this.state.company === '' || this.state.address === '' || this.state.email  === '' || this.state.phone  === '' || this.state.phone.length < 10) {
        alert("Please ensure all fields are valid.");
        return false;
    };
    return true;
  }

    render() {

      return (
      <div>
        <Container id="earlyaccess">
          <TextContent>
            {this.subheading && <Subheading>{this.subheading}</Subheading>}
            <Heading>{this.heading}</Heading>
            {this.description && <Description>{this.description}</Description>}
          </TextContent>

          <TwoColumn>


            <Form onSubmit={this.handleSubmit}>
              <Input onChange={(e) => this.handleChange(e)} id="name" type="text" name="name" placeholder="Full Name" />
              <Input onChange={(e) => this.handleChange(e)} id="company" type="text" name="company" placeholder="Company Name" />
              <Input onChange={(e) => this.handleChange(e)} id="address" type="text" name="address" placeholder="Company Address" />
              <Input onChange={(e) => this.handleChange(e)} id="email" type="email" name="email" placeholder="Email Address" />
              <Input onChange={(e) => this.handleChange(e)} id="phone" type="numeric" name="phone" placeholder="Phone Number" />
              <SubmitButton disabled={this.state.disabled} type="submit">{this.submitButtonText}</SubmitButton>
            </Form>
          </TwoColumn>

        </Container>

        </div>

      );
    }
}
export default FacilityContactForm;
