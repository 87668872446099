import React, { useState } from "react";
import tw from "twin.macro";
import "assets/styles/tailwind.css";
import { PrimaryButton as PrimaryButtonBase, SecondaryButton as SecondaryButtonBase} from "components/misc/Buttons.js";

const PrimaryButton = tw(PrimaryButtonBase)`mt-8 inline-block w-56 tracking-wide text-center py-5`;
  const buttonRoundedCss = tw`rounded-full`;


const headers = ["Page name", "Visitors", "Unique users", "Bounce rate"];

const tableData = [
  {
    pageName: "/argon/",
    visitors: "4,569",
    uniqueUsers: "340",
    bounceRate: "46,53%",
  },
  {
    pageName: "/argon/index.html",
    visitors: "3,985",
    uniqueUsers: "319",
    bounceRate: "46,53%",
  },
  // Add more data objects as needed
];

export default function CardPageVisits() {
  const [hoveredRow, setHoveredRow] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredRow(index);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const handleAddNew = () => {
    // Add functionality for adding new data here
    console.log("Add new button clicked");
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center justify-between">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-blueGray-700">
                Page visits
              </h3>
            </div>
            <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                 <PrimaryButton as="a" href="">
                                Add New
                              </PrimaryButton>
              <button onClick={handleAddNew} className="bg-purple-400 text-xs font-bold uppercase px-3 py-1 mr-1 mb-1 " type="button">
                Add new
              </button>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                {/* Generate table headers */}
                {headers.map((header, index) => (
                  <th
                    key={index}
                    className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {/* Generate table rows */}
              {tableData.map((data, index) => (
                <tr
                  key={index}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  {/* Table cells */}
                  {Object.keys(data).map((key, cellIndex) => (
                    <td
                      key={cellIndex}
                      className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 relative"
                    >
                      {data[key]}
                      {hoveredRow === index && cellIndex === 3 && (
                        // Display delete icon when hovering over the first cell (Page Name) in the hovered row
                        <i className="fas fa-trash text-red-500 absolute ml-4"></i>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
