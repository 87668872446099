import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import Modal from "react-modal";
import Sidebar from "components/Sidebar/Sidebar.js";
import MenuProvider from "components/Sidebar/What.js";
import { BrowserRouter } from "react-router-dom";
import Menu from "components/Sidebar/Menu.js";

Modal.setAppElement("#root");

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <MenuProvider MenuComponent={Menu}>
        <App />
      </MenuProvider>
);