import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import GlobeIcon from "images/globe.svg";
import UsersIcon from "images/users.svg";
import ChartIcon from "images/bar-chart-2.svg";
import CheckCircleIcon from "images/check-circle.svg";



import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import MoneyIcon from "images/dollar-sign.svg";

const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ThreeColumnContainer = styled.div`
  ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;
const Column = styled.div`
  ${tw`lg:w-1/3 max-w-xs`}
`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-8 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;

const ContainerCustom = tw.div`relative bg-primary-700 -mx-8 px-8 text-gray-100`;


export default ({
  cards = [
    {
      imageSrc: GlobeIcon,
      title: "Large Network",
      description: "Hire help and increase shift fulfillment by utilizing our large network of healthcare professionals awaiting new opportunities."
    },
    { imageSrc: ShieldIconImage, title: "Help Around the Clock", description: "Need help with coverage? You don't have to worry about business hours with us. Post on the app for an available professional to pick up. We are available 24/7 for technical support." },
    { imageSrc: MoneyIcon, title: "Reduce costs", description: "You only get billed after completion of a successful shift. Reduce costs by eliminating the middle-man of pricey traditional staffing agencies. There is nothing to lose." },
    { imageSrc: CheckCircleIcon, title: "Seamless hiring", description: "Recruitment process can be lengthy and tiresome, our system makes for a smooth and automated recruitment process - no phone calls required." },
    { imageSrc: CustomizeIconImage, title: "Real-time shift management", description: "Post multiple openings and shifts and manage them in real-time." },
    { imageSrc: ChartIcon, title: "Simplified Dashboard", description: "Simplified administrative controls and dashboard makes it easy to train your staff to manage contractors, schedules, billing and gaining valuable insight." }

  ],
  linkText = "Learn More",
  heading = "",
  subheading = "",
  description = "",
  imageContainerCss = null,
  imageCss = null
}) => {
  /*
   * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  4) url - the url that the card should goto on click
   */
  return (
    <ContainerCustom id="benefits">
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        {heading && <Heading>{heading}</Heading>}
        {description && <Description>{description}</Description>}
        <ThreeColumnContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <Card href={card.url}>
                <span className="imageContainer" css={imageContainerCss}>
                  <img src={card.imageSrc} stroke="green" alt="" css={imageCss} />
                </span>
                <span className="title">{card.title}</span>
                <p className="description">{card.description}</p>
                {/* {linkText && (
                  <span className="link">
                    <span>{linkText}</span>
                    <ArrowRightIcon className="icon" />
                  </span>
                )} */}
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
      </ContentWithPaddingXl>
      <DecoratorBlob />
    </ContainerCustom>
  );
};
