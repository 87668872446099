import React, { useRef } from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import ReactGA from 'react-ga';



import Hero from "components/hero/AgencyHero.js";
import Features from "components/features/AgencyFeatures";
import Pricing from "components/pricing/AgencyPricing";
import FAQ from "components/faqs/SingleCol.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ProductInformation from "components/blogs/AgencyFeatures.js";
import GetStarted from "components/cta/GetStartedLight.js";

import Testimonials from "components/testimonials/ThreeColumnWithProfileImage";




const HighlightedText = tw.span`text-primary-600`

const pathname = window.location.pathname;


export default () => {
   // track pageview
   ReactGA.pageview(window.location.pathname);

  return (
<>
    <AnimationRevealPage>
      <Hero />

      <ProductInformation />


      <Features heading={<>Why Agencies love us</> } id="benefits"/>

      {/* <ProductInformation headingTitle="Features" description="Framework to help take your agency to the next level" /> */}




      <Pricing
        heading={<>Flexible <HighlightedText>Plans</HighlightedText></>}
      />

      <Testimonials />

   
      {/* <FAQ
        heading={<>Any <HighlightedText>Questions ?</HighlightedText></>}
      /> */}

      {/* <GetStarted/> */}

      <Footer />

    </AnimationRevealPage>
</>

  );
}
