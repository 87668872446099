import React, { Component, useState } from "react";
import ReactGA from 'react-ga';
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import axios from 'axios';

import LogoImage from "images/logo.svg";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as LinkedInIcon } from "images/linkedin-icon.svg";

const Container = tw.div`relative bg-gray-200 text-gray-700 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const SixColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`px-4 sm:px-0 sm:w-1/4 md:w-auto mt-12`;

const ColumnHeading = tw.h5`uppercase font-bold`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300`;

const SubscribeNewsletterColumn = tw(Column)`text-center lg:text-left w-full! lg:w-auto! mt-20 lg:mt-12`;
const SubscribeNewsletterContainer = tw.div`max-w-sm mx-auto lg:mx-0 `;
const SubscribeText = tw.p`mt-2 lg:mt-6 text-sm font-medium text-gray-600`;
const SubscribeForm = tw.form`mt-4 lg:mt-6 text-sm sm:flex max-w-xs sm:max-w-none mx-auto sm:mx-0`;
const Input = tw.input`bg-gray-300 px-6 py-3 rounded sm:rounded-r-none border-2 sm:border-r-0 border-gray-400 hover:border-primary-500 focus:outline-none transition duration-300 w-full`;
const SubscribeButton = tw(PrimaryButtonBase)`mt-4 sm:mt-0 w-full sm:w-auto rounded sm:rounded-l-none px-8 py-3`;

const Divider = tw.div`my-16 border-b-2 border-gray-300 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-800`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-500`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-900 text-gray-100 hover:bg-gray-700 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            subject: '',
            disabled: false,
            };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.isValid = this.isValid.bind(this);
    }

    isValid() {
        if(this.state.email  === '') {return false;};
        return true;
      }

     handleChange(e){
          this.setState({[e.target.name]: e.target.value});
       }

    handleSubmit(event){
        event.preventDefault();

        if(this.isValid()) {
            axios.post(process.env.REACT_APP_CONVERTKIT_FORMS_URL + process.env.REACT_APP_CONVERTKIT_FORM_ID + '/subscribe?api_key=' + process.env.REACT_APP_CONVERTKIT_API_KEY + '&email=' + this.state.email)
              .then(function (response) {

                // Google analytics
                ReactGA.event({
                  category: 'Visitor',
                  action: 'Subscribed to newsletter'
                });

                alert("Thank you for your subscribing to our mailing list for all the latest news from Healthcare Link. Please check your email to confirm subscription.");
              })
              .catch(function (error) {
              });

            this.setState({disabled: true});
        }
    }

    render() {
        return (
            <Container>
              <Content>
                <SixColumns>
                  <Column>
                    <ColumnHeading>Company</ColumnHeading>
                    <LinkList>
                      <LinkListItem>
                        <Link href="/about">About</Link>
                      </LinkListItem>
                       <LinkListItem>
                          <Link href="/contact">Contact Us</Link>
                        </LinkListItem>
                    </LinkList>
                  </Column>

                  <Column>
                      <ColumnHeading>Professionals</ColumnHeading>
                      <LinkList>
                        <LinkListItem>
                          <Link href="/#openings">Openings</Link>
                        </LinkListItem>
                        <LinkListItem>
                          <Link href="/#benefits">Benefits</Link>
                        </LinkListItem>
                         <LinkListItem>
                          <Link as="a" href="/#testimonials">Testimonials</Link>
                        </LinkListItem>
                         <LinkListItem>
                          <Link as="a" href="/#download">Download App</Link>
                        </LinkListItem>
                      </LinkList>
                    </Column>

                  <Column>
                    <ColumnHeading>Facilities</ColumnHeading>
                    <LinkList>
                      <LinkListItem>
                        <Link href="/facilities/#howitworks">How It Works</Link>
                      </LinkListItem>
                      <LinkListItem>
                        <Link href="/facilities/#benefits">Benefits</Link>
                      </LinkListItem>
                       <LinkListItem>
                        <Link href="/facilities/#earlyaccess">Register for Early Access</Link>
                      </LinkListItem>
                    </LinkList>
                  </Column>

                   {/*}
                  <Column>
                    <ColumnHeading>Press</ColumnHeading>
                    <LinkList>
                      <LinkListItem>
                        <Link href="#">Press Kit</Link>
                      </LinkListItem>

                      <LinkListItem>
                        <Link href="#">Events</Link>
                      </LinkListItem>

                    </LinkList>
                  </Column>
                  */}


                  <Column>
                    <ColumnHeading>Legal</ColumnHeading>
                    <LinkList>
                      <LinkListItem>
                        <Link href="/privacypolicy">Privacy Policy</Link>
                      </LinkListItem>
                      <LinkListItem>
                        <Link href="/termsofservice">Terms of Service</Link>
                      </LinkListItem>
                    </LinkList>
                  </Column>
                  <SubscribeNewsletterColumn>
                    <SubscribeNewsletterContainer>
                      <ColumnHeading>Subscribe to our Newsletter</ColumnHeading>
                      <SubscribeText>
                        Stay up to date on the latest news from Healthcare Link
                      </SubscribeText>
                      <SubscribeForm onSubmit={this.handleSubmit}>
                        <Input onChange={(e) => this.handleChange(e)} name="email" type="email" placeholder="Your Email Address" />
                        <SubscribeButton disabled={this.state.disabled} type="submit">Subscribe</SubscribeButton>
                      </SubscribeForm>
                    </SubscribeNewsletterContainer>
                  </SubscribeNewsletterColumn>
                </SixColumns>
                <Divider />
                <ThreeColRow>
                  <LogoContainer>
                  {/*}
                    <LogoImg src={LogoImage} />
                    */}
                    <LogoText>Healthcare Link</LogoText>
                  </LogoContainer>
                  <CopywrightNotice>&copy; 2023 Vestova Inc. All Rights Reserved.</CopywrightNotice>

                  <SocialLinksContainer>
                  {/*
                    <SocialLink href="https://facebook.com">
                      <FacebookIcon />
                    </SocialLink>
                    <SocialLink href="https://twitter.com">
                      <TwitterIcon />
                    </SocialLink>
                    */}
                    <SocialLink href="https://linkedin.com">
                      <LinkedInIcon />
                    </SocialLink>
                  </SocialLinksContainer>
                </ThreeColRow>
              </Content>
            </Container>
          );
    }
}

export default Footer;


//export default () => {
//  return (
//    <Container>
//      <Content>
//        <SixColumns>
//          <Column>
//            <ColumnHeading>Company</ColumnHeading>
//            <LinkList>
//              <LinkListItem>
//                <Link href="/about">About</Link>
//              </LinkListItem>
//               <LinkListItem>
//                  <Link href="/contact">Contact Us</Link>
//                </LinkListItem>
//            </LinkList>
//          </Column>
//
//          <Column>
//              <ColumnHeading>Professionals</ColumnHeading>
//              <LinkList>
//                <LinkListItem>
//                  <Link href="/#listings">Openings</Link>
//                </LinkListItem>
//                <LinkListItem>
//                  <Link href="/#benefits">Benefits</Link>
//                </LinkListItem>
//                 <LinkListItem>
//                  <Link as="a" href="/#testimonials">Testimonials</Link>
//                </LinkListItem>
//                 <LinkListItem>
//                  <Link as="a" href="/#download">Download App</Link>
//                </LinkListItem>
//              </LinkList>
//            </Column>
//
//          <Column>
//            <ColumnHeading>Facilities</ColumnHeading>
//            <LinkList>
//              <LinkListItem>
//                <Link href="/">How It Works</Link>
//              </LinkListItem>
//              <LinkListItem>
//                <Link href="/">Benefits</Link>
//              </LinkListItem>
//               <LinkListItem>
//                <Link href="/">Register for Early Access</Link>
//              </LinkListItem>
//            </LinkList>
//          </Column>
//
//           {/*}
//          <Column>
//            <ColumnHeading>Press</ColumnHeading>
//            <LinkList>
//              <LinkListItem>
//                <Link href="#">Press Kit</Link>
//              </LinkListItem>
//
//              <LinkListItem>
//                <Link href="#">Events</Link>
//              </LinkListItem>
//
//            </LinkList>
//          </Column>
//          */}
//
//
//          <Column>
//            <ColumnHeading>Legal</ColumnHeading>
//            <LinkList>
//              <LinkListItem>
//                <Link href="/privacypolicy">Privacy Policy</Link>
//              </LinkListItem>
//              <LinkListItem>
//                <Link href="/termsofservice">Terms of Service</Link>
//              </LinkListItem>
//            </LinkList>
//          </Column>
//          <SubscribeNewsletterColumn>
//            <SubscribeNewsletterContainer>
//              <ColumnHeading>Subscribe to our Newsletter</ColumnHeading>
//              <SubscribeText>
//                Stay up to date on the latest news from Healthcare Link
//              </SubscribeText>
//              <SubscribeForm method="get" action="#">
//                <Input type="email" placeholder="Your Email Address" />
//                <SubscribeButton type="submit">Subscribe</SubscribeButton>
//              </SubscribeForm>
//            </SubscribeNewsletterContainer>
//          </SubscribeNewsletterColumn>
//        </SixColumns>
//        <Divider />
//        <ThreeColRow>
//          <LogoContainer>
//          {/*}
//            <LogoImg src={LogoImage} />
//            */}
//            <LogoText>Healthcare Link</LogoText>
//          </LogoContainer>
//          <CopywrightNotice>&copy; 2023 Vestova Inc. All Rights Reserved.</CopywrightNotice>
//
//          <SocialLinksContainer>
//          {/*
//            <SocialLink href="https://facebook.com">
//              <FacebookIcon />
//            </SocialLink>
//            <SocialLink href="https://twitter.com">
//              <TwitterIcon />
//            </SocialLink>
//            */}
//            <SocialLink href="https://linkedin.com">
//              <LinkedInIcon />
//            </SocialLink>
//          </SocialLinksContainer>
//        </ThreeColRow>
//      </Content>
//    </Container>
//  );
//};
