import React from "react";
import ReactGA from 'react-ga';
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
    // track pageview
    ReactGA.pageview(window.location.pathname);

  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>About Healthcare Link</Subheading>}
        heading="A modern solution to healthcare staffing."
        description="&nbsp;&nbsp;&nbsp;At Healthcare Link,we understand the challenges faced by both healthcare professionals and facilities in managing shift scheduling. Whether it's unexpected absences, staffing shortages, or the need for additional support during peak times, our platform offers a reliable solution to bridge the gap and maintain smooth operations.

                     <br>&nbsp;&nbsp;&nbsp;Our platform is designed to empower healthcare professionals by providing them with the flexibility and freedom to choose shifts that fit their schedule and preferences. By offering a wide range of opportunities from various healthcare facilities, we enable professionals to take control of their career and work-life balance.

                     <br>&nbsp;&nbsp;&nbsp;For healthcare facilities, we offer a comprehensive and efficient solution to manage staffing needs. Our platform allows facilities to easily post available shifts, specify required qualifications, and connect with qualified professionals in real-time. With our extensive network of healthcare professionals, facilities can quickly find the right match, ensuring optimal patient care and seamless operations.

                     <br>&nbsp;&nbsp;&nbsp;What sets Healthcare Link apart is our commitment to quality and reliability. We carefully vet and verify all healthcare professionals on our platform, ensuring that they possess the necessary credentials and qualifications. This not only provides peace of mind for facilities but also maintains a high standard of care throughout the industry."
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        imageSrc="
https://images.unsplash.com/photo-1557825835-3185803dd474?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80
"
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="Harmonious healthcare landscape."
        buttonRounded={false}
        description="&nbsp;&nbsp;&nbsp;Our mission is to foster a thriving healthcare community where professionals have the freedom to shape their careers and facilities can easily access the support they need. We are driven by the belief that by empowering healthcare professionals and improving staffing processes, we can contribute to the delivery of exceptional patient care and make a positive impact on the healthcare industry as a whole."
        primaryButtonText="Contact Us"
        imageSrc="
https://images.unsplash.com/photo-1655890006065-edefcd764af6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1932&q=80"
        textOnLeft={false}
      />

      {/*}
      <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
        ]}
        linkText=""
      />

      <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      />
      */}

      <Footer />
    </AnimationRevealPage>
  );
};
