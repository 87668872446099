import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ContentWithPaddingXl, Container } from "components/misc/Layouts.js";
import { SectionHeading as Heading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

const Subheading = tw(SubheadingBase)`text-center`;
const Testimonials = tw.div`flex flex-col lg:flex-row items-center lg:items-stretch`;
const TestimonialContainer = tw.div`mt-16 lg:w-1/3`;
const Testimonial = tw.div`px-4 text-center max-w-xs mx-auto flex flex-col items-center`;
const Image = tw.img`w-20 h-20 rounded-full`;
const Quote = tw.blockquote`mt-5 text-gray-600 font-medium leading-loose`;
const CustomerName = tw.p`mt-5 text-gray-900 font-semibold uppercase text-sm tracking-wide`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
`;

const generateCircleStyle = () => {
  return {
    width: '80px',
    height: '80px',
    background: 'linear-gradient(to bottom, #5C73B9, #8860FF)',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '24px',
    fontWeight: 'bold',
  };
};


export default ({
  subheading = "Testimonials",
  heading = "Customer's Review",
  testimonials = [
    {
      quote:
        "I am extremely grateful for the convenience and flexibility provided by this app. As a new parent, I often have unpredictable schedules and varying availability. This platform has made it effortless for me to find shifts and short-term contracts that align perfectly with my schedule.",
      customerName: "Isha Patel",
      initials: "I P",
    },
    {
      quote:
      "I have been using this app for a while now, and it has completely changed the way I approach my work schedule. It's incredibly user-friendly and intuitive.",
        customerName: "Gabrielle Ferguson",
      initials: "G F",
    },
    {
    quote:
      "This app has streamlined the entire process of picking up extra shifts and made it so much easier to connect with healthcare institutions in need of additional staff.",
      customerName: "Kevin Brennan",
    initials: "K B",
    }
  ]
}) => {
  return (
    <Container id="testimonials">
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        <Testimonials>
          {testimonials.map((testimonial, index) => (
            <TestimonialContainer key={index}>
              <Testimonial>
                <div style={generateCircleStyle()}>{testimonial.initials}</div>
                <Quote>"{testimonial.quote}"</Quote>
                <CustomerName>- {testimonial.customerName}</CustomerName>
              </Testimonial>
            </TestimonialContainer>
          ))}
        </Testimonials>
      </ContentWithPaddingXl>

      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
