import React, { useRef } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import ReactGA from 'react-ga';



import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import RecentListings from "components/cards/ThreeColSlider.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Blog from "components/blogs/GridWithFeaturedPost.js";
import Testimonial from "components/testimonials/ProviderTestimonial.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStartedLight.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import DownloadApp from "components/cta/DownloadApp.js";

const HighlightedText = tw.span`text-primary-600`

const pathname = window.location.pathname;


export default () => {
   // track pageview
   ReactGA.pageview(window.location.pathname);

  return (
<>
    <AnimationRevealPage>
      <Hero />

      {/*<FeatureStats/> */}

      <RecentListings id="listings"/>


      <Features heading={<>Why Providers love us</> } id="benefits"/>




      {/*
      <MainFeature
        heading={<>Cloud built by and for <HighlightedText>Professionals</HighlightedText></>}
      />
      */}



      {/*
      <Pricing
        heading={<>Flexible <HighlightedText>Plans</HighlightedText></>}
      />
    */}

      {/*
      <FAQ
        heading={<>Any <HighlightedText>Questions ?</HighlightedText></>}
      />


      <GetStarted/>
       */}

         <Testimonial
                   heading={<>Don't just take our <HighlightedText>word</HighlightedText></>}
                 />

               <DownloadApp  id="download" />


                            <Footer />

    </AnimationRevealPage>



</>

  );
}
