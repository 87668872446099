import React, { Component, useState } from "react";
import ReactGA from 'react-ga';
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import emailjs from '@emailjs/browser';
import DocImage from "../../assets/img/contact-us.jpg"



const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0 w-96`;
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

class ContactUsForm extends Component {

  constructor(props) {
    	super(props);
    	this.state = {
    	    name: '',
    	    email: '',
    	    subject: '',
    	    disabled: false,
    	    };
    	this.handleChange = this.handleChange.bind(this);
    	this.handleSubmit = this.handleSubmit.bind(this);
    	  	this.isValid = this.isValid.bind(this);


      }

      handleChange(e){
             this.setState({[e.target.name]: e.target.value});

          }

        handleSubmit(event){
          event.preventDefault();

          if(this.isValid())
          {
            var templateParams = {
                name: this.state.name,
                email: this.state.email,
                subject: this.state.subject,
                message: this.state.message,
            };

      	    emailjs.send(process.env.REACT_APP_CONVERTKIT_API_SERVICE_ID, process.env.REACT_APP_CONVERTKIT_EMAIL_TEMPLATE_CONTACT, templateParams, process.env.REACT_APP_CONVERTKIT_API_PUBLIC_KEY);

            ReactGA.event({
              category: 'Visitor',
              action: 'Sent a message'
            });

            alert("Thank you for your contacting us. Someone from the Healthcare Link team will get back to you shortly.");
            this.setState({disabled: true});
          } else {
            alert("Please make sure the form is properly completed.");
          }

        }

        isValid() {
          if(this.state.name === '' || this.state.email === '' || this.state.subject === '' || this.state.message  === '') {return false;};
          return true;
        }

        render() {

              return (
               <Container>
                    <TwoColumn>
                      <ImageColumn>
                        <Image imageSrc={DocImage}/>
                      </ImageColumn>
                      <TextColumn textOnLeft={true}>
                        <TextContent>
                          <Subheading>Contact Us</Subheading>
                          <Heading><>Feel free to <span tw="text-primary-600">get in touch</span><wbr/> with us.</></Heading>
                          <Description>Have any questions or concerns? Reach out to us and we will get back to you before you know it.</Description>
                          <Form onSubmit={this.handleSubmit}>
                            <Input onChange={(e) => this.handleChange(e)} type="text" name="name" placeholder="Full Name" />
                            <Input onChange={(e) => this.handleChange(e)} type="email" name="email" placeholder="Your Email Address" />
                            <Input onChange={(e) => this.handleChange(e)} type="text" name="subject" placeholder="Subject" />
                            <Textarea onChange={(e) => this.handleChange(e)} name="message" placeholder="Your Message Here" />
                            <SubmitButton disabled={this.state.disabled} type="submit">Send</SubmitButton>
                          </Form>
                        </TextContent>
                      </TextColumn>
                    </TwoColumn>
                  </Container>
              );
            }
}

export default ContactUsForm;
